<template>
  <div>
    <div>
      <img
        id="background-canvas"
        :src="videoBackgroundImage"
        width="1280px"
        height="720px"
        style="pointer-events: none; z-index: 548878; top: 0px; left: 0px; opacity: 0; width: 300px; height: 200px; position: fixed"
      />
    </div>
  </div>
</template>
<script>
import { webrtc } from '@/mixins/webrtc'
import { mmultistreammixer } from '@/mixins/mmultistreammixer'
//import * as Sentry from '@sentry/vue'
import { mapGetters, mapMutations } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const CALL_RECORDING_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  CALL_RECORDING: 'call-recording',
  STOPPING: 'stopping'
}
export default {
  mixins: [webrtc, mmultistreammixer],
  props: {
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedSpeaker: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    callRecordingStatus: {
      type: String,
      required: true
    },
    hasVisitorMadeOffer: {
      type: Boolean,
      required: true
    },
    clearWebRtc: {
      type: Boolean,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      prevSelectedAudio: null,
      prevSelectedVideo: null,
      roomRef: null,
      makingOffer: false,
      callerCandidates: null,
      calleeCandidates: null,
      localStream: null,
      canvasStream: null,
      selfieSegmentation: null,
      remoteStream: null,
      peerConnection: null,
      mediaRecorder: null,
      hasSessionStarted: false,
      recordedBlobs: [],
      iceCandidates: [],
      recordingMIMEType: 'video/webm;codecs=vp9,opus',
      callInitiator: 'agent',
      callInitiatedInMode: null,
      rtcSessionDescription: null,
      hasSetRemoteDescription: false,
      videoElement: null,
      backgroundImage: null,
      canvasElement: null,
      canvasCtx: null,
      selectedBackground: null,
      defaultBackgrounds: [
        { type: 'none', selected: true, default: true },
        { type: 'blur', selected: false, default: true },
        { type: 'img', source: '/img/vb/vb01.jpg', selected: false, default: true },
        { type: 'img', source: '/img/vb/vb02.jpg', selected: false, default: true },
        { type: 'img', source: '/img/vb/vb03.jpg', selected: false, default: true },
        { type: 'img', source: '/img/vb/vb04.jpg', selected: false, default: true },
        { type: 'img', source: '/img/vb/vb05.jpg', selected: false, default: true },
        { type: 'img', source: '/img/vb/vb06.jpg', selected: false, default: true },
        { type: 'img', source: '/img/vb/vb07.jpg', selected: false, default: true }
      ],
      reportKeys: [
        'candidate-pair',
        //'codec',
        'csrc',
        'data-channel',
        'ice-server',
        'inbound-rtp',
        'local-candidate',
        'media-source',
        'outbound-rtp',
        'peer-connection',
        'receiver',
        'remote-candidate',
        'remote-inbound-rtp',
        'remote-outbound-rtp',
        'sctp-transport',
        'track',
        'sender',
        'transceiver',
        'transport'
      ],
      statisticsKeys: [
        'kind',
        'audioLevel',
        'state',
        'localCandidateId',
        'remoteCandidateId',
        'packetsSent',
        'packetsReceived',
        'bytesSent',
        'bytesReceived',
        'requestsSent',
        'requestsReceived',
        'responsesSent',
        'responsesReceived',
        'totalRoundTripTime',
        'currentRoundTripTime',
        'ip',
        'address',
        'port',
        'protocol',
        'candidateType'
      ],
      shouldCheckPeerConnectionState: false,
      mixer: null
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      micEnabled: 'webrtc/micEnabled',
      videoEnabled: 'webrtc/videoEnabled',
      cameraStream: 'webrtc/cameraStream',
      screenStream: 'webrtc/screenStream',
      hasWebrtcCommunicationStarted: 'webrtc/hasWebrtcCommunicationStarted',
      messageModes: 'webrtc/messageModes',
      messageMode: 'webrtc/messageMode',
      audioVideoStarted: 'webrtc/audioVideoStarted',
      visitorId: 'webrtc/visitorId',
      visitor: 'webrtc/visitor',
      blurBackground: 'webrtc/blurBackground',
      company: 'company'
    }),
    configuration() {
      const configuration = {
        iceServers: [
          {
            url: 'stun:global.stun.twilio.com:3478',
            urls: ['stun:global.stun.twilio.com:3478']
          }
        ],
        iceCandidatePoolSize: 5
      }
      if (this.visitor.iceServers) {
        configuration.iceServers = this.visitor.iceServers
        configuration.iceTransportPolicy = 'relay'
      }

      const turnServer = configuration.iceServers.filter((x) => x.url.indexOf('turn') > -1) //.slice(0, 2)
      configuration.iceServers = turnServer

      return configuration
    },
    isVideoDisabled() {
      return Boolean(this.company && this.company.disableVideo)
    },
    IS_AGENT_SCREEN_SHARING() {
      return (
        this.screenSharingStatus &&
        this.screenSharingStatus.initiatedBy === 'agent' &&
        (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STARTED ||
          this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING)
      )
    },
    IS_BLUR_BACKGROUND() {
      return Boolean(this.blurBackground)
    },
    videoBackgroundImage() {
      return !this.isMobile && this.selectedBackground && this.selectedBackground.source ? this.selectedBackground.source : '/img/vb/vb01.jpg'
    },
    agentName() {
      let name = ''
      if (this.activeUserInfo.firstname) {
        name = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          name = `${name} ${this.activeUserInfo.lastname}`
        }
      } else if (this.activeUserInfo.displayName) {
        name = this.activeUserInfo.displayName
      }
      return name
    },
    audioDevice() {
      if (this.selectedAudio && this.selectedAudio.value && this.selectedAudio.value !== 'microphone-off') {
        return {
          deviceId: this.selectedAudio.value,
          echoCancellation: true
        }
      } else {
        return {
          deviceId: 'default',
          echoCancellation: true
        }
      }
    },
    videoDevice() {
      if (this.isVideoDisabled || !this.videoEnabled) {
        return false
      }

      if (this.selectedVideo && this.selectedVideo.value && this.selectedVideo.value !== 'camera-off') {
        return {
          deviceId: this.selectedVideo.value,
          width: 1280,
          height: 720
        }
      }

      return {
        deviceId: 'default',
        width: 1280,
        height: 720
      }
    },
    videoStream() {
      if (this.IS_AGENT_SCREEN_SHARING) {
        return this.cameraStream
      }

      return this.localStream
    },

    videoTrackStream() {
      if (!this.IS_AGENT_SCREEN_SHARING && this.cameraStream) {
        return this.cameraStream
      }

      if (this.IS_AGENT_SCREEN_SHARING && !this.videoEnabled) {
        return this.screenStream
      }

      return this.localStream
    },
    audioStream() {
      return this.localStream
    }
  },
  watch: {
    async 'selectedAudio.value'() {
      if (this.peerConnection && this.selectedAudio && this.selectedAudio.value && this.localStream) {
        // Stop the tracks
        const tracks = this.localStream.getAudioTracks()
        if (tracks) {
          if (this.prevSelectedAudio !== this.selectedAudio.value && this.selectedAudio.value !== 'microphone-off') {
            tracks.forEach((track) => track.stop())
            await this.startLocalStream()
          } else {
            tracks[0].enabled = this.micEnabled
          }
        } else {
          await this.startLocalStream()
        }
      }
    },
    async 'selectedVideo.value'() {
      if (this.peerConnection && this.selectedVideo && this.selectedVideo.value && this.localStream) {
        // Stop the tracks
        const tracks = this.localStream.getVideoTracks()
        if (tracks && tracks.length > 0) {
          if (this.prevSelectedVideo !== this.selectedVideo.value && this.selectedVideo.value !== 'camera-off') {
            tracks.forEach((track) => track.stop())
            await this.startLocalStream()
          } else {
            tracks[0].enabled = this.videoEnabled
          }
        } else {
          await this.startLocalStream()
        }
      }
    },
    async screenSharingStatus() {
      if (this.screenSharingStatus.initiatedBy === 'agent' && this.visitorId) {
        if (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STARTED) {
          if (!this.peerConnection) {
            this.initiateCall(this.screenSharingStatus.initiatedBy)
          } else {
            const result = await this.addScreenToCameraStream()
            if (!result) {
              return false
            }

            this.$database
              .ref(`/screen-share/${this.visitorId}`)
              .set({ status: this.SCREEN_SHARING_STATUS.SCREEN_SHARING, initiatedBy: 'agent', updateKey: Math.random().toString(36).substring(2, 15) })
          }
        }

        if (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STOPPED) {
          if (!this.audioVideoStarted) {
            if (this.peerConnection) {
              this.peerConnection.close()
              this.peerConnection = null
            }

            this.stopStreamTracks(this.cameraStream)
            this.setCameraStream(null)

            this.setScreenStream(null)
          } else {
            this.removeMixer()
          }
          this.replaceAudioTrack()
          this.replaceVideoTrack()

          setTimeout(() => {
            this.stopStreamTracks(this.screenStream)
          }, 1500)
        }
      }
    },
    hasVisitorMadeOffer() {
      if (this.hasVisitorMadeOffer) {
        this.callInitiator = 'visitor'
      }
    },
    clearWebRtc() {
      if (this.clearWebRtc) {
        this.destroyWebRTC()
      }
    },
    visitorId() {
      const vm = this

      if (vm.visitorId) {
        /* ADD LISTENER */
        vm.$database.ref(`/av-call/${vm.visitorId}`).on('value', (snapshot) => {
          const data = snapshot.val()
          if (data && data.initiator) {
            vm.callInitiator = data.initiator

            /*
            Visitor is using safari and trying to renegotiate.
            Visitor will have created an offer
            Agent should create an answer
          */
            if (data.renegotiate || data.renegotiatedAgentCall) {
              // Destroy References
              this.clearRealtimeDBListeners()
              if (this.peerConnection) {
                this.peerConnection.close()
              }

              // Update roomRef reference
              this.roomRef = this.$database.ref(`/webrtc/${this.visitorId}/visitor`)
              this.callerCandidates = this.roomRef.child('callerCandidates')
              this.calleeCandidates = this.roomRef.child('calleeCandidates')

              // Create an answer for safari
              this.initiateCall(vm.callInitiator)
              // this.createAnswer()
            }
          }
        })

        vm.$database.ref(`/screen-share/${vm.visitorId}`).on('value', (snapshot) => {
          const data = snapshot.val()
          if (data) {
            this.setScreenSharingStatus(data)
          }
        })
      }
    },
    async audioVideoStarted() {
      if (this.audioVideoStarted && !this.hasWebrtcCommunicationStarted) {
        this.$database.ref(`/av-call/${this.visitorId}`).once('value', async (snapshot) => {
          const data = snapshot.val()
          if (data && data.initiator === 'agent') {
            this.callInitiator = 'agent'
            this.$database
              .ref(`/av-call/${this.visitorId}`)
              .set({ initiator: 'agent', visitorIsCalling: false, agentIsCalling: true, key: Math.random().toString(36).substring(2, 15) })
            this.$database.ref(`/webrtc/${this.visitorId}/agent`).set(null)
            this.$database.ref(`/webrtc/${this.visitorId}/visitor`).set(null)
          }
          if (this.audioVideoStarted && this.visitorId) {
            this.initiateCall(this.callInitiator)
          }
        })
      }
    },
    'activeUserInfo.backgrounds'() {
      if (this.canvasCtx) {
        this.canvasCtx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height)
        this.canvasCtx.save()
      }

      const backgrounds = this.activeUserInfo && this.activeUserInfo.backgrounds ? this.activeUserInfo.backgrounds : this.defaultBackgrounds
      const selectedBackground = backgrounds.find((x) => x.selected)
      this.selectedBackground = selectedBackground || this.defaultBackgrounds.find((x) => x.selected)
      if (this.selectedBackground && this.selectedBackground.type === 'img' && this.selectedBackground.source) {
        this.backgroundImage = document.getElementById('background-canvas')
        this.backgroundImage.crossOrigin = 'anonymous'
      }
    },

    selectedBackground() {
      if (this.selectedBackground && this.audioVideoStarted) {
        this.replaceVideoTrack()
      }
    },

    async videoEnabled() {
      if (!this.videoTrackStream) {
        return
      }
      if (this.callInitiator === 'visitor') {
        const tracks = this.videoTrackStream.getVideoTracks()
        if (!tracks || tracks.length === 0) {
          await this.startLocalStream()
        } else {
          tracks.forEach((track) => {
            track.enabled = this.videoEnabled
          })
        }
      }
    },

    async messageModes() {
      if (this.videoStream && this.videoStream.getAudioTracks().length > 0) {
        const tracks = this.videoStream.getAudioTracks()
        tracks.forEach((track) => {
          track.enabled = this.messageModes.includes('audio')
        })
      }
      if (this.videoStream && this.videoStream.getVideoTracks().length > 0) {
        const tracks = this.videoStream.getVideoTracks()
        tracks.forEach((track) => {
          track.enabled = this.messageModes.includes('video')
        })
      }

      if (this.messageMode === 'video' && this.videoEnabled) {
        if (!this.cameraStream && this.IS_AGENT_SCREEN_SHARING) {
          await this.addCameraToStream()
        }

        if (this.cameraStream && this.IS_AGENT_SCREEN_SHARING) {
          this.updateCameraInStream()
        }

        if (this.audioVideoStarted) {
          this.replaceAudioTrack()
          this.replaceVideoTrack()
        }
      }
    },
    // Only called for negotitation e.g. call started with audio and then upgrades to video
    async messageMode() {
      if (
        this.audioVideoStarted &&
        this.callInitiatedInMode === 'audio' &&
        this.messageMode === 'video' &&
        this.localStream &&
        this.callInitiator === 'agent' &&
        !this.IS_AGENT_SCREEN_SHARING
      ) {
        const audioTracks = this.localStream.getAudioTracks()
        if (audioTracks) {
          audioTracks.forEach((track) => track.stop())
        }
        const videoTracks = this.localStream.getVideoTracks()
        if (videoTracks) {
          videoTracks.forEach((track) => track.stop())
        }
        await this.startLocalStream()
      }
    },

    callRecordingStatus() {
      if (this.callRecordingStatus === CALL_RECORDING_STATUS.STARTED) {
        this.startCallRecording()
      }

      if (this.callRecordingStatus === CALL_RECORDING_STATUS.STOPPING) {
        this.stopRecording()
      }
    },
    shouldCheckPeerConnectionState() {
      /* Once the remote description is set, Check the connection state after 8 seconds  */
      /* If the Connection state is not 'connected' then log the getStats info of the peer connection to Sentry */
      if (this.shouldCheckPeerConnectionState && this.$config.env === 'prod') {
        setTimeout(() => {
          this.checkConnectionState()
        }, 8000)
      }
    }
  },
  beforeMount() {
    const backgrounds = this.activeUserInfo && this.activeUserInfo.backgrounds ? this.activeUserInfo.backgrounds : this.defaultBackgrounds
    const selectedBackground = backgrounds.find((x) => x.selected)
    this.selectedBackground = selectedBackground || this.defaultBackgrounds.find((x) => x.selected)
    if (this.selectedBackground && this.selectedBackground.type === 'img' && this.selectedBackground.source) {
      this.backgroundImage = document.getElementById('background-canvas')
      if (this.backgroundImage) {
        this.backgroundImage.crossOrigin = 'anonymous'
      }
    }
  },
  beforeDestroy() {
    this.localStream = null
    this.remoteStream = null
    this.stopAllTracks()
    this.setLocalStream(null)
    this.shouldCheckPeerConnectionState = false
  },
  methods: {
    ...mapMutations({
      setLocalStream: 'webrtc/setLocalStream',
      setScreenSharingStatus: 'webrtc/setScreenSharingStatus',
      setVideoEnabled: 'webrtc/setVideoEnabled',
      setMicEnabled: 'webrtc/setMicEnabled',
      setCameraStream: 'webrtc/setCameraStream',
      setScreenStream: 'webrtc/setScreenStream',
      setHasWebrtcCommunicationStarted: 'webrtc/setHasWebrtcCommunicationStarted',
      setAudioVideoCallStarted: 'webrtc/setAudioVideoCallStarted'
    }),
    initCanvas() {
      this.videoElement = document.getElementById('local-video')
      this.canvasElement = document.getElementById('output_canvas')
      this.backgroundImage = document.getElementById('background-canvas')
      if (this.videoElement && this.canvasElement) {
        this.videoElement.width = this.canvasElement.width
        this.videoElement.height = this.canvasElement.height
        this.canvasCtx = this.canvasElement.getContext('2d')
      }
    },

    replaceVideoBackground(results) {
      if (!this.backgroundImage) {
        this.backgroundImage = document.getElementById('background-canvas')
      }

      if (this.backgroundImage && results.segmentationMask) {
        this.canvasCtx.globalCompositeOperation = 'copy'

        // Flip the canvas and prevent mirror behavior.
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)

        try {
          this.canvasCtx.drawImage(results.segmentationMask, 0, 0, 1280, 720, 0, 0, 1280, 720)
        } catch (error) {
          // eslint-disable-next-line
        }

        // Reset the transformations
        this.canvasCtx.setTransform(1, 0, 0, 1, 0, 0)

        this.canvasCtx.globalCompositeOperation = 'source-in'
        this.canvasCtx.filter = 'none'

        // Flip the canvas and prevent mirror behavior.
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)

        this.canvasCtx.drawImage(results.image, 0, 0)

        // Reset the transformations
        this.canvasCtx.setTransform(1, 0, 0, 1, 0, 0)

        this.canvasCtx.globalCompositeOperation = 'destination-over'
        this.canvasCtx.filter = 'blur(2px)'
        this.canvasCtx.drawImage(this.backgroundImage, 0, 0, 1280, 720)
      }
    },
    blurVideoBackground(results, blur) {
      if (blur === 'none') {
        this.canvasCtx.save()
        this.canvasCtx.filter = 'none'
        this.canvasCtx.clearRect(0, 0, this.videoElement.width, this.videoElement.height)
        this.canvasCtx.globalCompositeOperation = 'xor'
        this.canvasCtx.drawImage(results.image, 0, 0, this.videoElement.width, this.videoElement.height)
        this.canvasCtx.restore()
      } else if (results && results.segmentationMask && this.canvasCtx && this.canvasElement) {
        this.canvasCtx.globalCompositeOperation = 'copy'

        // Smooth out the edges.
        this.canvasCtx.filter = blur

        // Save current context before applying transformations.
        this.canvasCtx.save()

        // Flip the canvas and prevent mirror behaviour.
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)

        this.canvasCtx.drawImage(
          results.segmentationMask,
          0,
          0,
          this.canvasElement.width,
          this.canvasElement.height,
          0,
          0,
          this.videoElement.width,
          this.videoElement.height
        )
        this.canvasCtx.restore()

        this.canvasCtx.globalCompositeOperation = 'source-in'
        this.canvasCtx.filter = 'none'

        // Draw the foreground video.
        // Save current context before applying transformations.
        this.canvasCtx.save()

        // Flip the canvas and prevent mirror behaviour.
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)
        this.canvasCtx.drawImage(results.image, 0, 0, this.videoElement.width, this.videoElement.height)
        this.canvasCtx.restore()

        // Draw the background.
        // Save current context before applying transformations.
        this.canvasCtx.save()
        this.canvasCtx.globalCompositeOperation = 'destination-over'
        this.canvasCtx.filter = blur
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)
        this.canvasCtx.drawImage(results.image, 0, 0, this.videoElement.width, this.videoElement.height)
        this.canvasCtx.restore()
      }
    },

    onResults(results) {
      const selectedBackground = this.selectedBackground
      const type = selectedBackground && selectedBackground.type ? selectedBackground.type : 'none'
      switch (type) {
        case 'none':
          this.blurVideoBackground(results, 'none')
          break

        case 'img':
          this.replaceVideoBackground(results)
          break

        case 'blur':
          this.blurVideoBackground(results, 'blur(16px)')
          break

        default:
          break
      }
    },

    async setupInitiator() {
      const snapshot = await this.$database.ref(`/av-call/${this.visitorId}`).once('value')
      const data = snapshot.val()
      if ((data && data.initiator === 'agent') || this.callInitiator === 'agent') {
        this.callInitiator = 'agent'
        this.$database
          .ref(`/av-call/${this.visitorId}`)
          .set({ initiator: 'agent', visitorIsCalling: false, agentIsCalling: true, key: Math.random().toString(36).substring(2, 15) })
        this.$database.ref(`/webrtc/${this.visitorId}/visitor`).set(null)
      }
    },

    async replaceAudioTrack() {
      if (this.peerConnection && this.videoStream && this.videoStream.getAudioTracks().length > 0) {
        // Mute if microphone is not enabled
        this.videoStream.getAudioTracks().forEach((track) => {
          track.enabled = this.micEnabled
        })
        const audioTrack = this.videoStream.getAudioTracks()[0]
        const sender = this.peerConnection.getSenders().find((s) => {
          if (!s.track) return false
          return s.track.kind === audioTrack.kind
        })
        if (sender) {
          sender.replaceTrack(audioTrack)
        } else {
          await this.peerConnection.addTrack(audioTrack, this.videoStream)
        }
      }
    },

    async replaceVideoTrack() {
      if (this.IS_AGENT_SCREEN_SHARING) {
        this.initCanvas()
        this.sendVideoTrack()
      } else if (this.peerConnection && this.localStream && this.localStream.getVideoTracks().length > 0) {
        this.initCanvas()
        if (this.isMobile || !this.selectedBackground || this.selectedBackground.type === 'none') {
          this.sendVideoTrack()
        } else {
          if (!this.selfieSegmentation) {
            // eslint-disable-next-line
            this.selfieSegmentation = new SelfieSegmentation({
              locateFile: (file) => {
                return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1.1675465747/${file}`
              }
            })
            this.selfieSegmentation.setOptions({
              modelSelection: 1
            })
            this.selfieSegmentation.onResults(this.onResults)
          }

          if (!this.camera) {
            const deviceId = this.selectedVideo && this.selectedVideo.value ? this.selectedVideo.value : 'default'
            // eslint-disable-next-line
            this.camera = new Camera(this.videoElement, {
              onFrame: async () => {
                if (this.selfieSegmentation && this.videoElement) {
                  if (this.selectedBackground && this.selectedBackground.type !== 'none') {
                    await this.selfieSegmentation.send({ image: this.videoElement, background: this.selectedBackground.type })
                  }
                }
              },
              width: 1280,
              height: 720,
              deviceId
            })
            this.camera.start()
          }

          if (this.canvasElement) {
            this.canvasStream = this.canvasElement.captureStream(30)
            this.sendCanvasTrack()
          }
        }
      }

      this.$emit('update-webrtc-stream', {
        localStream: this.localStream,
        remoteStream: this.remoteStream
      })
    },

    async sendVideoTrack() {
      if (this.videoTrackStream && this.peerConnection) {
        if (this.videoElement) {
          this.videoElement.style['opacity'] = 1
          this.videoElement.style['position'] = 'absolute'
          this.videoElement.style['width'] = '100%'
          this.videoElement.style['height'] = '100%'
        }

        if (this.canvasElement) {
          this.canvasElement.style['opacity'] = 0
        }

        const videoTrack = this.videoTrackStream.getVideoTracks()[0]
        if (videoTrack) {
          const sender = this.peerConnection.getSenders().find((s) => {
            if (!s.track) return false

            return s.track.kind === videoTrack.kind
          })

          if (sender) {
            sender.replaceTrack(videoTrack)
          } else {
            await this.peerConnection.addTrack(videoTrack, this.videoTrackStream)
          }
        }
      }
    },

    async sendCanvasTrack() {
      if (this.canvasStream && this.peerConnection) {
        if (this.videoElement) {
          this.videoElement.style['opacity'] = 0
          this.videoElement.style['position'] = 'absolute'
          this.videoElement.style['width'] = '1px'
          this.videoElement.style['height'] = '1px'
        }

        if (this.canvasElement) {
          this.canvasElement.style['opacity'] = 1
        }

        const videoTrack = this.canvasStream.getVideoTracks()[0]

        const sender = this.peerConnection.getSenders().find((s) => {
          if (!s.track) return false
          return s.track.kind === videoTrack.kind
        })

        if (sender) {
          sender.replaceTrack(videoTrack)
        } else {
          await this.peerConnection.addTrack(videoTrack, this.canvasStream)
        }
      }
    },

    getConstraints() {
      const constraints = {
        audio: this.audioDevice,
        video: this.videoDevice
      }

      return constraints
    },

    setVideoTrackContentHints(stream, hint) {
      const tracks = stream.getVideoTracks()
      tracks.forEach((track) => {
        if ('contentHint' in track) {
          track.contentHint = hint
          if (track.contentHint !== hint) {
            /* eslint-disable no-console */
            console.log(`Invalid video track contentHint: ${hint}`)
          }
        } else {
          /* eslint-disable no-console */
          console.log('MediaStreamTrack contentHint attribute not supported')
        }
      })
    },

    stopStreamTracks(stream) {
      if (stream) {
        if (typeof stream.getTracks === 'undefined') {
          stream.stop()
        } else {
          stream.getAudioTracks().forEach(function (track) {
            track.stop()
          })

          stream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
      }
    },

    removeMixer() {
      if (this.mixer && this.mixer.releaseStreams) {
        this.mixer.releaseStreams()
      }
      this.mixer = null
      this.setLocalStream(this.cameraStream)
      this.localStream = this.cameraStream
    },

    async addScreenToCameraStream() {
      try {
        const videoConstraints = {}

        videoConstraints.frameRate = { ideal: 12 }
        videoConstraints.width = screen.width
        videoConstraints.height = screen.height
        videoConstraints.cursor = 'always'

        let screenStream = null

        try {
          screenStream = await navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: videoConstraints
          })
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
          if (!this.audioVideoStarted && this.peerConnection) {
            this.peerConnection.close()
            this.peerConnection = null
          }
          await this.$database.ref(`/screen-share/${this.visitorId}`).set({
            status: this.SCREEN_SHARING_STATUS.ENDED,
            initiatedBy: this.screenSharingStatus.initiatedBy,
            updateKey: Math.random().toString(36).substring(2, 15)
          })
          return false
        }

        screenStream.fullcanvas = true
        screenStream.width = screen.width // or 3840
        screenStream.height = screen.height // or 2160
        screenStream.videoType = 'screen'

        this.setScreenStream(screenStream)
        this.setVideoTrackContentHints(screenStream, 'detail')

        if (!this.cameraStream) {
          this.stopStreamTracks(this.localStream)
          this.stopStreamTracks(this.cameraStream)
          const constraints = this.getConstraints()
          const cameraStream = await navigator.mediaDevices.getUserMedia(constraints)
          const tracks = cameraStream.getVideoTracks()
          tracks.forEach((track) => {
            track.enabled = this.videoEnabled
          })

          this.setCameraStream(cameraStream)
        }

        const cameraWidth = 150
        const cameraHeight = 100

        this.cameraStream.width = cameraWidth
        this.cameraStream.height = cameraHeight
        this.cameraStream.top = this.screenStream.height - cameraHeight - 50
        this.cameraStream.left = this.screenStream.width - cameraWidth - 50

        this.cameraStream.videoType = 'camera'

        if (!this.mixer) {
          this.mixer = new this.MultiStreamsMixer([this.screenStream, this.cameraStream])
          this.mixer.frameInterval = 30
          this.mixer.startDrawingFrames()
        } else {
          this.updateCameraInStream()
        }
        const multistream = this.mixer.getMixedStream()
        this.setLocalStream(multistream)
        this.localStream = multistream

        if (this.screenStream && this.screenStream.getVideoTracks().length > 0) {
          this.screenStream.getVideoTracks()[0].addEventListener('ended', async () => {
            if (this.visitorId) {
              this.stopStreamTracks(this.screenStream)
              this.setScreenStream(null)
              if (!this.audioVideoStarted) {
                this.stopStreamTracks(this.cameraStream)
                this.setCameraStream(null)
              } else {
                this.removeMixer()
              }
              this.replaceAudioTrack()
              this.replaceVideoTrack()

              await this.$database.ref(`/screen-share/${this.visitorId}`).set({
                status: this.SCREEN_SHARING_STATUS.ENDED,
                initiatedBy: this.screenSharingStatus.initiatedBy,
                updateKey: Math.random().toString(36).substring(2, 15)
              })
            }
          })
        }

        await this.$database.ref(`/screen-share/${this.visitorId}`).set({
          status: this.SCREEN_SHARING_STATUS.SCREEN_SHARING,
          initiatedBy: this.screenSharingStatus.initiatedBy,
          updateKey: Math.random().toString(36).substring(2, 15)
        })

        await this.replaceAudioTrack()
        await this.replaceVideoTrack()
        return true
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
        return false
      }
    },

    async addCameraToStream() {
      try {
        const constraints = this.getConstraints()

        if (this.cameraStream) {
          this.stopStreamTracks(this.localStream)
          this.stopStreamTracks(this.cameraStream)
        }
        const cameraStream = await navigator.mediaDevices.getUserMedia(constraints)
        const tracks = cameraStream.getVideoTracks()
        tracks.forEach((track) => {
          track.enabled = this.videoEnabled
        })

        const cameraWidth = 150
        const cameraHeight = 100

        cameraStream.width = cameraWidth
        cameraStream.height = cameraHeight
        cameraStream.top = this.screenStream.height - cameraHeight - 50
        cameraStream.left = this.screenStream.width - cameraWidth - 50

        cameraStream.videoType = 'camera'
        this.setCameraStream(cameraStream)

        if (!this.mixer) {
          this.mixer = new this.MultiStreamsMixer([this.screenStream, this.cameraStream])
          this.mixer.frameInterval = 30
          this.mixer.startDrawingFrames()
        } else {
          this.updateCameraInStream()
        }

        const multistream = this.mixer.getMixedStream()
        this.localStream = multistream
        this.setLocalStream(multistream)
      } catch (error) {
        console.log(error.message)
      }
    },

    async updateCameraInStream() {
      if (!this.videoEnabled) {
        this.mixer.resetVideoStreams([this.screenStream])
      } else {
        this.mixer.resetVideoStreams([this.screenStream, this.cameraStream])
      }
    },

    async startScreenStream() {
      try {
        if (!this.screenStream) {
          const videoConstraints = {}

          videoConstraints.frameRate = { ideal: 12 }
          videoConstraints.width = screen.width
          videoConstraints.height = screen.height
          videoConstraints.cursor = 'always'

          let screenStream = null
          try {
            screenStream = await navigator.mediaDevices.getDisplayMedia({
              audio: false,
              video: videoConstraints
            })
          } catch (error) {
            /* eslint-disable no-console */
            console.log(error.message)
            if (!this.audioVideoStarted && this.peerConnection) {
              this.peerConnection.close()
              this.peerConnection = null
            }
            await this.$database.ref(`/screen-share/${this.visitorId}`).set({
              status: this.SCREEN_SHARING_STATUS.ENDED,
              initiatedBy: this.screenSharingStatus.initiatedBy,
              updateKey: Math.random().toString(36).substring(2, 15)
            })
            return false
          }

          screenStream.fullcanvas = true
          screenStream.width = screen.width // or 3840
          screenStream.height = screen.height // or 2160
          screenStream.videoType = 'screen'

          this.setScreenStream(screenStream)
          this.setVideoTrackContentHints(this.screenStream, 'detail')

          if (this.screenStream && this.screenStream.getVideoTracks().length > 0) {
            this.screenStream.getVideoTracks()[0].addEventListener('ended', async () => {
              if (this.visitorId) {
                await this.$database.ref(`/screen-share/${this.visitorId}`).set({
                  status: this.SCREEN_SHARING_STATUS.ENDED,
                  initiatedBy: this.screenSharingStatus.initiatedBy,
                  updateKey: Math.random().toString(36).substring(2, 15)
                })
              }

              if (!this.audioVideoStarted) {
                this.destroyWebRTC()
              }
            })
          }
        }

        if (this.micEnabled || this.videoEnabled) {
          const constraints = this.getConstraints()
          if (this.cameraStream) {
            this.stopStreamTracks(this.cameraStream)
            this.setCameraStream(null)
          }

          const cameraStream = await navigator.mediaDevices.getUserMedia(constraints)
          const tracks = cameraStream.getVideoTracks()
          tracks.forEach((track) => {
            track.enabled = this.videoEnabled
          })

          const cameraWidth = 150
          const cameraHeight = 100

          cameraStream.width = cameraWidth
          cameraStream.height = cameraHeight
          cameraStream.top = this.screenStream.height - cameraHeight - 50
          cameraStream.left = this.screenStream.width - cameraWidth - 50

          cameraStream.videoType = 'camera'
          this.setCameraStream(cameraStream)

          if (!this.mixer) {
            this.mixer = new this.MultiStreamsMixer([this.screenStream, this.cameraStream])
            this.mixer.frameInterval = 30
            this.mixer.startDrawingFrames()
          } else {
            this.updateCameraInStream()
          }
          const multistream = this.mixer.getMixedStream()
          this.localStream = multistream
          this.setLocalStream(multistream)
        }

        await this.$database.ref(`/screen-share/${this.visitorId}`).set({
          status: this.SCREEN_SHARING_STATUS.SCREEN_SHARING,
          initiatedBy: this.screenSharingStatus.initiatedBy,
          updateKey: Math.random().toString(36).substring(2, 15)
        })
        return true
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
        this.destroyWebRTC()
        await this.$database.ref(`/screen-share/${this.visitorId}`).set({
          status: this.SCREEN_SHARING_STATUS.ENDED,
          initiatedBy: this.screenSharingStatus.initiatedBy,
          updateKey: Math.random().toString(36).substring(2, 15)
        })
        return false
      }
    },

    async startCameraStream() {
      try {
        const constraints = this.getConstraints()
        this.stopStreamTracks(this.localStream)
        this.stopStreamTracks(this.cameraStream)

        const stream = await navigator.mediaDevices.getUserMedia(constraints)

        this.localStream = stream
        this.setCameraStream(stream)
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
        return false
      }
    },

    async startLocalStream() {
      if (this.selectedAudio && this.selectedAudio.value && this.selectedAudio.value !== 'microphone-off') {
        this.prevSelectedAudio = this.selectedAudio.value
      }
      if (this.selectedVideo && this.selectedVideo.value && this.selectedVideo.value !== 'camera-off') {
        this.prevSelectedVideo = this.selectedVideo.value
      }

      this.hasSetRemoteDescription = false

      if (this.IS_AGENT_SCREEN_SHARING) {
        const result = await this.startScreenStream()
        if (!result) {
          return false
        }
      } else {
        await this.startCameraStream()
      }

      // Replace the Audio Track
      this.replaceAudioTrack()

      // Replace the Video Track
      this.replaceVideoTrack()

      this.setHasWebrtcCommunicationStarted(true)

      return true
    },
    initiateCall(callerType) {
      this.setupInitiator()
      this.callInitiator = callerType
      this.callInitiatedInMode = this.messageMode
      switch (callerType) {
        case 'agent':
          this.roomRef = this.$database.ref(`/webrtc/${this.visitorId}/agent`)
          this.callerCandidates = this.roomRef.child('callerCandidates')
          this.calleeCandidates = this.roomRef.child('calleeCandidates')
          this.startAudioVideoCommunication()
          break

        case 'visitor':
          this.roomRef = this.$database.ref(`/webrtc/${this.visitorId}/visitor`)
          this.callerCandidates = this.roomRef.child('callerCandidates')
          this.calleeCandidates = this.roomRef.child('calleeCandidates')
          this.startAudioVideoCommunication()
          break
      }
    },
    checkVideoReady() {
      if (this.callInitiator === 'visitor') {
        const remoteVideoTracks = this.remoteStream.getVideoTracks()
        const remoteAudioTracks = this.remoteStream.getAudioTracks()
        if (remoteVideoTracks && remoteVideoTracks.length > 0) {
          this.remoteStreamCount = remoteVideoTracks.filter((x) => x.enabled).length
          if (this.remoteStreamCount > 0) {
            this.$serverBus.$emit('visitor-video-ready', true)
          }
        }
        if (remoteAudioTracks && remoteAudioTracks.length > 0) {
          const audioStreamCount = remoteAudioTracks.filter((x) => x.enabled).length
          if (audioStreamCount > 0) {
            this.$serverBus.$emit('visitor-audio-ready', true)
          }
        }
        this.$emit('update-webrtc-stream', { localStream: this.localStream, remoteStream: this.remoteStream })
      }
    },
    addIceCandidatesListener() {
      this.peerConnection.addEventListener('icecandidate', (event) => {
        if (!event.candidate) {
          if (this.callInitiator === 'visitor') {
            this.checkVideoReady()
            return
          }
          return
        }
        if (this.callInitiator === 'visitor') {
          this.calleeCandidates.push().set(event.candidate.toJSON())
        }

        if (this.callInitiator === 'agent') {
          this.callerCandidates.push().set(event.candidate.toJSON())
        }
      })
    },
    addTrackEventsListener() {
      this.peerConnection.addEventListener('track', (event) => {
        if (!event.streams[0] || event.streams[0].getTracks().length === 0) {
          return
        }

        event.streams[0].getTracks().forEach((track) => {
          this.remoteStream.addTrack(track)
          this.setRemoteVideo()
          this.$emit('update-webrtc-stream', { localStream: this.localStream, remoteStream: this.remoteStream })
          this.$serverBus.$emit('visitor-media-track-state')
        })
      })
    },

    setRemoteVideo() {
      if (document.getElementById('remote-video')) {
        document.getElementById('remote-video').srcObject = this.remoteStream
      }
    },

    async createOffer() {
      const offerOptions = {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1
      }
      const offer = await this.peerConnection.createOffer(offerOptions)
      await this.peerConnection.setLocalDescription(offer)

      const roomWithOffer = {
        offer: {
          type: offer.type,
          sdp: offer.sdp,
          messageMode: this.messageMode
        },
        negotiate: false
      }
      await this.roomRef.set(roomWithOffer)
    },
    async createAnswer() {
      if (this.callInitiator === 'visitor') {
        const roomSnap = await this.roomRef.once('value')
        if (!roomSnap || !roomSnap.val() || !roomSnap.val().offer) {
          // eslint-disable-next-line
          console.log('Empty offer')
          return
        }
        this.rtcSessionDescription = new RTCSessionDescription(roomSnap.val().offer)
        await this.peerConnection.setRemoteDescription(this.rtcSessionDescription)
        this.shouldCheckPeerConnectionState = true

        const answer = await this.peerConnection.createAnswer()
        await this.peerConnection.setLocalDescription(answer)

        const roomWithAnswer = {
          answer: {
            type: answer.type,
            sdp: answer.sdp
          }
        }
        await this.roomRef.update(roomWithAnswer)
      }
    },
    addRemoteIceCandidatesListener() {
      if (this.callInitiator === 'visitor') {
        this.callerCandidates.on('child_added', async (snapshot) => {
          const data = snapshot.val()
          const iceCandidate = new RTCIceCandidate(data)
          if (iceCandidate) {
            await this.peerConnection.addIceCandidate(iceCandidate)
          }
        })
      }
      if (this.callInitiator === 'agent') {
        this.calleeCandidates.on('child_added', async (snapshot) => {
          const data = snapshot.val()
          const iceCandidate = new RTCIceCandidate(data)
          if (iceCandidate) {
            await this.peerConnection.addIceCandidate(iceCandidate)
          }
        })
      }
    },
    addRoomListener() {
      this.roomRef.on('value', async (snapshot) => {
        const data = snapshot.val()
        if (!data) {
          return
        }
        if (this.peerConnection && data.negotiate && data.initiator === 'visitor') {
          const description = { sdp: data.sdp, type: data.type }
          this.rtcSessionDescription = new RTCSessionDescription(description)
          if (this.rtcSessionDescription) {
            await this.peerConnection.setRemoteDescription(this.rtcSessionDescription)
          }
          this.shouldCheckPeerConnectionState = true
          if (data.type === 'offer') {
            await this.peerConnection.setLocalDescription()

            const dataDescription = {
              type: this.peerConnection.localDescription.type,
              sdp: this.peerConnection.localDescription.sdp,
              negotiate: true,
              initiator: 'agent'
            }
            await this.roomRef.set(dataDescription)
          }
        }

        if (
          this.peerConnection &&
          !this.peerConnection.currentRemoteDescription &&
          data &&
          data.answer &&
          this.peerConnection.signalingState !== 'stable' &&
          this.peerConnection.signalingState !== 'closed' &&
          !this.hasSetRemoteDescription
        ) {
          this.hasSetRemoteDescription = true
          this.rtcSessionDescription = new RTCSessionDescription(data.answer)
          if (this.rtcSessionDescription) {
            await this.peerConnection.setRemoteDescription(this.rtcSessionDescription)
            this.shouldCheckPeerConnectionState = true
            for (const iceCandidate of this.iceCandidates) {
              if (iceCandidate) {
                await this.peerConnection.addIceCandidate(iceCandidate)
              }
            }
          }
        }
      })
    },
    checkConnectionState() {
      if (this.peerConnection && this.peerConnection.connectionState !== 'connected') {
        let agent_id = ''
        let agent = ''

        this.peerConnection.getStats(null).then((stats) => {
          let statsOutput = ''
          agent_id = this.activeUserInfo && this.activeUserInfo.uid ? this.activeUserInfo.uid : ''
          agent = this.activeUserInfo && this.activeUserInfo.displayName ? this.activeUserInfo.displayName : ''

          stats.forEach((report) => {
            if (this.reportKeys.includes(report.type)) {
              statsOutput += `\n${report.type}, ID: ${report.id}\nTimestamp: ${report.timestamp}\n`

              // Now the statistics for this report; we intentially drop the ones we
              // sorted to the top above
              Object.keys(report).forEach((statName) => {
                if (this.statisticsKeys.includes(statName)) {
                  statsOutput += `${statName}: ${report[statName]}\n`
                }
              })
            }
          })
          statsOutput = `Webrtc Statistics\nAgent ID:${agent_id},Agent Name: ${agent},Visitor ID:${this.visitorId}\n${statsOutput}`
          /* eslint-disable no-console */
          console.error(statsOutput)
          //Sentry.captureMessage(`Agent Webrtc Stats: ${agent}, visitor_id: ${this.visitorId}, date: ${dayjs().format('LLL')}`)
        })
      }
    },
    async startAudioVideoCommunication() {
      this.peerConnection = new RTCPeerConnection(this.configuration)
      this.remoteStream = new MediaStream()

      this.stopAllTracks()
      const result = await this.startLocalStream() // Use Await to attach streams to PeerConnection
      if (!result) return

      this.addIceCandidatesListener()
      this.addTrackEventsListener()

      this.peerConnection.onnegotiationneeded = async () => {
        if (this.peerConnection.connectionState !== 'connected') {
          return false
        }
        try {
          this.makingOffer = true
          await this.peerConnection.setLocalDescription()
          const dataDescription = {
            type: this.peerConnection.localDescription.type,
            sdp: this.peerConnection.localDescription.sdp,
            negotiate: true,
            initiator: 'agent'
          }
          await this.roomRef.set(dataDescription)
        } catch (err) {
          // eslint-disable-next-line
          console.error(err)
        } finally {
          this.makingOffer = false
        }
      }
      if (this.callInitiator === 'visitor') {
        await this.createAnswer()
      }
      if (this.callInitiator === 'agent') {
        await this.createOffer()
      }
      this.addRemoteIceCandidatesListener()
      this.addRoomListener()
    },
    clearRealtimeDBListeners() {
      if (this.roomRef) {
        this.roomRef.off()
      }
      this.roomRef = null
      if (this.callerCandidates) {
        this.callerCandidates.off('child_added')
        this.callerCandidates = null
      }

      if (this.calleeCandidates) {
        this.calleeCandidates.off('child_added')
        this.calleeCandidates = null
      }
    },
    stopAllTracks() {
      if (this.camera && this.camera.stop) {
        this.camera.stop()
      }

      if (this.camera && this.camera.video && this.camera.video.srcObject) {
        if (typeof this.camera.video.srcObject.getTracks === 'undefined') {
          this.camera.video.srcObject.stop()
        } else {
          this.camera.video.srcObject.getAudioTracks().forEach(function (track) {
            track.stop()
          })

          this.camera.video.srcObject.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
      }

      this.stopStreamTracks(this.localStream)
      this.stopStreamTracks(this.remoteStream)
      this.stopStreamTracks(this.canvasStream)
      this.stopStreamTracks(this.screenStream)
      this.stopStreamTracks(this.cameraStream)
    },
    async destroyWebRTC() {
      if (this.mixer && this.mixer.releaseStreams) {
        this.mixer.releaseStreams()
      }

      this.setAudioVideoCallStarted(false)

      this.stopAllTracks()

      this.setMicEnabled(false)
      this.setVideoEnabled(false)

      this.hasSetRemoteDescription = false
      this.clearRealtimeDBListeners()
      this.prevSelectedAudio = null
      this.prevSelectedVideo = null

      this.localStream = null
      this.remoteStream = null
      this.setLocalStream(null)
      this.setCameraStream(null)
      this.setScreenStream(null)
      if (this.peerConnection) {
        this.peerConnection.close()
      }
      this.peerConnection = null
      this.mediaRecorder = null
      this.callInitiator = 'agent'
      this.hasSessionStarted = false
      this.recordedBlobs = []
      this.iceCandidates = []
      this.callInitiatedInMode = null
      this.selfieSegmentation = null
      this.camera = null
      this.shouldCheckPeerConnectionState = false
      this.setHasWebrtcCommunicationStarted(false)
    }
  }
}
</script>
